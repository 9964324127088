import styled from 'styled-components';

export const MobileProductCardContainer = styled.div`
  background: ${(props) => props.theme.palette.background.bg1};
  box-shadow: ${(props) => props.theme.shadows.s2};
  padding: 16px;
  border-radius: 8px;
  a {
    width: 100%;
    display: grid;
    grid-template-columns: 88px 1fr;
    grid-gap: 16px;
  }
  transition: 0.3s;
  & img {
    border-radius: 8px;
  }
  &:active {
    transform: scale(0.9);
  }
`;

export const ImageContainer = styled.div`
  min-width: 88px;
  margin: 0;
`;

export const DetailsContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

export const Title = styled.p`
  margin: 0;
  font-family: Onest-Bold;
  font-size: 16px;
  font-family: 'Onest-Bold', 'Inter', sans-serif;
  line-height: 18px;
  max-height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const ProductInfoContainer = styled.div`
  align-self: end;
  padding-bottom: 4px;
`;

export const Stat = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 8px;
  color: ${(props) => props.theme.palette.text.t3};
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: Onest-Medium;
`;

export const Price = styled.p`
  font-family: Onest-Bold;
  font-size: 16px;
  line-height: 19.2px;
  color: ${(props) => props.theme.palette.text.t1};
`;

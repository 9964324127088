import React from 'react';

import Head from 'next/head';
import withAuth from '../../HOC/withAuth';
import Favourites from '../../components/Favourites/Favourites';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Navbar from '../../components/Navbar/Navbar';

import { getUserInfoSSR } from '../../utils/cf';
import { BodyContainer } from '../../styles/pageStyles/favoritesStyle';

const Favorites: React.FC = () => {
  return (
    <>
      <Head>
        <title>Favorites - Driffle </title>
      </Head>
      <Header />
      <Navbar />
      <BodyContainer>
        <Favourites />
      </BodyContainer>
      <Footer />
    </>
  );
};

export default withAuth(Favorites);

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}

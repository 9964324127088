import Image from 'next/image';
import React from 'react';

import {
  displayCurrencyValue,
  getRegionFromRegionId,
} from '../../helper/helper';
import { ProductCardInterface } from '../../Interface/ProductCardInterface';
import { useAppSelector } from '../../redux/hooks';
import { exportPlatformIcon } from '../Icons/PlatformIcons';
import {
  DetailsContainer,
  ImageContainer,
  MobileProductCardContainer,
  Price,
  ProductInfoContainer,
  Stat,
  Title,
} from './MobileProductCardStyles';

import Link from 'next/dist/client/link';
import { useLocale } from '../../contexts/LocalizationContext';
interface Props {
  product: ProductCardInterface;
}

const MobileProductCard: React.FC<Props> = ({ product }) => {
  const { messages } = useLocale();
  const { sold_out_msg } = messages || {};

  const { locationDetails } = useAppSelector((state) => state.global);

  return (
    <MobileProductCardContainer>
      <Link passHref href={`/${product?.slug}`}>
        <a>
          <ImageContainer>
            <Image
              src={product.image}
              width="88px"
              height="128px"
              alt={'Product Image - ' + product.title}
              loader={() => product?.image}
              unoptimized
            />
          </ImageContainer>

          <DetailsContainer>
            <Title>{product?.title}</Title>
            <ProductInfoContainer>
              <Stat>
                {product.platform &&
                  exportPlatformIcon(product.platform, '20px', '20px', '0')}
                <p>
                  {product?.regionName
                    ? product?.regionName
                    : getRegionFromRegionId(product!.regionId) || '-'}
                </p>
              </Stat>

              <Price>
                {' '}
                {product?.price
                  ? displayCurrencyValue(
                      product.price,
                      locationDetails?.currency_symbol,
                      locationDetails?.multiplier
                    )
                  : sold_out_msg}
              </Price>
            </ProductInfoContainer>
          </DetailsContainer>
        </a>
      </Link>
    </MobileProductCardContainer>
  );
};

export default MobileProductCard;

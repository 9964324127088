import Skeleton from '@mui/material/Skeleton';
import axios from 'axios';
import Image from 'next/image';
import React, { useContext, useEffect, useState } from 'react';
import { ProductCardInterface } from '../../Interface/ProductCardInterface';
import ProductCardG from '../../components/ProductCard/ProductCardG';
import { apiConstants } from '../../constants/apiConstants';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { MaskIcon } from '../Icons/Icons';
import MobileProductCard from '../MobileProductCard/MobileProductCard';

import Link from 'next/dist/client/link';
import { useLocale } from '../../contexts/LocalizationContext';
import { sendRecombeeBookmarkEvent } from '../../utils/recombee';
import Button from '../NewButton/Button';
import {
  EmptyFavouritesContainer,
  FavouritesContainer,
  FavouritesList,
  FavouritesListItem,
  FavouritesSearch,
  LikeButtonContainer,
  TitleContainer,
} from './FavouritesStyles';

const Favourites: React.FC = () => {
  const { messages } = useLocale();
  const {
    remove_product_favorite_msg,
    something_went_wrong_msg,
    favourites_msg,
    search_games_gift_msg,
    add_products_favourites_msg,
    go_to_store_msg,
    your_favourites_empty_msg,
  } = messages || {};

  const { width } = useWindowDimensions();
  const { dispatchSnackbar } = useContext(SnackbarContext);

  const [favouriteProducts, setFavouriteProducts] = useState<
    ProductCardInterface[]
  >([]);
  const [visibleFavProducts, setVisibleFavProducts] = useState<
    ProductCardInterface[]
  >([]);
  const [searchText, setSearchText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get(apiConstants.server + '/private/wishlist', { withCredentials: true })
      .then(({ data }) => {
        setFavouriteProducts(data.data);
        setVisibleFavProducts(data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setVisibleFavProducts(() => {
      const result = favouriteProducts.filter((prod) => {
        return String(prod.title)
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      return result;
    });
  }, [searchText, favouriteProducts]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const handleRemoveFromWishlist = (pid: number, productSku: string) => {
    axios
      .post(
        apiConstants.server + '/private/wishlist/remove',
        { pid },
        {
          withCredentials: true,
        }
      )
      .then(({ data }) => {
        setFavouriteProducts((prev) => [
          ...prev.filter((prod) => prod.productId != pid),
        ]);
        sendRecombeeBookmarkEvent(String(productSku), 'remove');
        dispatchSnackbar({
          type: 'success',
          payload: remove_product_favorite_msg ?? '',
        });
      })
      .catch((e) => {
        dispatchSnackbar({
          type: 'failure',
          payload: something_went_wrong_msg ?? '',
        });
      });
  };

  return (
    <FavouritesContainer>
      <TitleContainer>
        <h2>{favourites_msg}</h2>
        <FavouritesSearch
          onChange={handleInputChange}
          value={searchText}
          placeholder={search_games_gift_msg}
        />
      </TitleContainer>
      <FavouritesList>
        {!loading ? (
          visibleFavProducts.length === 0 ? (
            <EmptyFavouritesContainer>
              <Image
                src="https://static.driffle.com/images/marginalia-1161-1.svg"
                width="242px"
                height="242px"
                unoptimized
                priority
                alt="empty favourites"
              />
              <h2>{your_favourites_empty_msg}</h2>
              <p>{add_products_favourites_msg}</p>
              <Link passHref prefetch={false} href="/store">
                <a>
                  <Button
                    buttonTheme="primary"
                    height="48px"
                    width="180px"
                    // onClick={() => router.push('/store')}
                    ariaLabel="Go to store"
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                      }}
                    >
                      <MaskIcon
                        url="/icons/store-24.svg"
                        selected={true}
                        color={'#fff'}
                        width="24px"
                        height="24px"
                        margin="0"
                      />{' '}
                      {go_to_store_msg}
                    </span>
                  </Button>
                </a>
              </Link>
            </EmptyFavouritesContainer>
          ) : (
            <>
              {visibleFavProducts.map((prod, i) =>
                width &&
                width <= getNumFromResolutionConstant(WIDTH.mobileMax) ? (
                  <FavouritesListItem mobile={true} key={i}>
                    <LikeButtonContainer
                      onClick={() =>
                        handleRemoveFromWishlist(
                          prod!.productId!,
                          prod!.productSku!
                        )
                      }
                    >
                      <MaskIcon
                        url="/icons/heart.svg"
                        height="18.35px"
                        width="20px"
                        selected={true}
                        color={COLORS.red}
                        margin="0"
                      />
                    </LikeButtonContainer>{' '}
                    <MobileProductCard product={prod} />
                  </FavouritesListItem>
                ) : (
                  <FavouritesListItem key={i}>
                    <LikeButtonContainer
                      onClick={() =>
                        handleRemoveFromWishlist(
                          prod!.productId!,
                          prod!.productSku!
                        )
                      }
                    >
                      <MaskIcon
                        url="/icons/heart.svg"
                        height="18.35px"
                        width="20px"
                        selected={true}
                        color={COLORS.red}
                        margin="0"
                      />
                    </LikeButtonContainer>
                    <ProductCardG product={prod} index={i} />
                  </FavouritesListItem>
                )
              )}
            </>
          )
        ) : width && width <= getNumFromResolutionConstant(WIDTH.mobileMax) ? (
          <>
            {Array(5)
              .fill(0)
              .map((_, i) => (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  width={'100%'}
                  height={184}
                  style={{ borderRadius: '8px' }}
                />
              ))}
          </>
        ) : (
          <>
            {Array(10)
              .fill(0)
              .map((_, i) => (
                <Skeleton
                  key={i}
                  variant="rectangular"
                  width={200}
                  height={284}
                  style={{ borderRadius: '8px' }}
                />
              ))}
          </>
        )}
      </FavouritesList>
    </FavouritesContainer>
  );
};

export default Favourites;

import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const FavouritesContainer = styled.div`
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 34px;
  & h2 {
    margin: 0;
    font: 2rem/2rem Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
    flex-grow: 1;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const FavouritesSearch = styled.input`
  border: none;
  outline: none;
  background: ${(props) => props.theme.palette.background.bg1};
  padding: 12px 16px;
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t1};
  width: 305px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.s4};
  &::placeholder {
    color: ${(props) => props.theme.palette.text.t3};
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
  }
`;

export const FavouritesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: start;
  @media (max-width: ${WIDTH.mobileMax}) {
    flex-direction: column;
    gap: 20px;
  }
`;

interface FavouritesListItemProps {
  mobile?: boolean;
}

export const FavouritesListItem = styled.div<FavouritesListItemProps>`
  max-width: 200px;
  min-width: 180px;
  position: relative;
  & > div:last-of-type {
    padding-right: ${(props) => (props.mobile ? '60px' : '0')};
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    max-width: 100%;
  }
`;

export const EmptyFavouritesContainer = styled.div`
  margin: auto;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr;
  width: fit-content;
  & img {
  }
  & h2 {
    font-size: 24px;
    line-height: 20px;
    font-family: Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
    margin: 0;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  & p {
    font-size: 16px;
    line-height: 20px;
    font-family: Onest-Regular;
    color: ${(props) => props.theme.palette.text.t3};
    margin-bottom: 14px;
    max-width: 327px;
    text-align: center;
  }
`;

export const LikeButtonContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 40px;
  z-index: 3;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
  border-radius: 50%;
  background: ${COLORS.red2};
  display: flex;
  place-content: center;
  place-items: center;
  transition: 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.9);
  }
`;
